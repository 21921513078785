import React from "react";
import { Column } from "../helpers/layout";
import { BoldSubTitle } from "../typography";
import { Button } from "./Button";
import Router from "next/router";

export function AlreadyAuthenticated() {
  return (
    <Column align="center">
      <BoldSubTitle>You are logged in</BoldSubTitle>
      <Button
        type="button"
        label="Continue shopping"
        onClick={() => {
          Router.push(`/shop`);
        }}
      />
    </Column>
  );
}
