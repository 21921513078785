import { notAuthorised } from "../request";
import { Announcement } from "../types/Announcement";

export async function getAnnouncements(location: string) {
  let announcements: Announcement[] = [];

  try {
    const announcementsRequest = await notAuthorised.request<Announcement[]>({
      method: "GET",
      url: `/api/frontend/announcements/?location=${location}`,
    });
    announcements = announcementsRequest.data;
  } catch {
    announcements = [];
  }

  return announcements;
}
